import React from "react";
import NewHeader from "../components/newHeader";
import { useLocation } from "react-router-dom";
import BlueButton from "../../Component/UI/BlueButton";
import { Col, Container, Row } from "react-bootstrap";
import { CoachIcon, Menplayer } from "../../assets";
import moment from "moment";

const Appliedplayers = () => {
  const location = useLocation();

  const AppliedplayersList = location?.state?.data?.profilesApplied;

  console.log(AppliedplayersList, "AppliedplayersList");

  const UserComponent = () => {
    return (
      <div className="coachJobs coachContainer">
        <NewHeader name={"Players applied"} path="/" />

        <div className="coachJobFilter">
          <div className="filters">
            <BlueButton active={true}>All</BlueButton>
            <BlueButton>Open</BlueButton>
            <BlueButton>Closed</BlueButton>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px", overflowX: "auto" }}></div>
      </div>
    );
  };

  return (
    <>
      <Container
        className="create-account-container profileCoach coachJobs"
        style={{
          paddingBottom: "150px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <UserComponent />

        <Row style={{ marginTop: "20px" }}>
         
          {AppliedplayersList &&
            AppliedplayersList?.map((item, index) => {
              return (
                <Col style={{ marginTop: "20px" }} key={index} xs={12}>
                  <div className="experiences">
                    <img src={item?.profile?.profileImage || Menplayer} alt="$$$" />
                    <div>
                      <h2>{item?.profile?.title}</h2>

                      <p>
                        {moment(item?.startDate).format("MMM YYYY")} -{" "}
                        {item?.currentlyWorking
                          ? "Present"
                          : moment(item?.endDate).format("MMM YYYY")}
                      </p>
                    </div>
                  </div>
                  <hr />
                </Col>
              );
            })}
        </Row>
      </Container>
    </>
  );
};

export default Appliedplayers;
