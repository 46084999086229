/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useRef, useContext } from "react";

// Components

import Header from "../../../components/Header/Header";

// Package

import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import Chart from "react-apexcharts";
import cloneDeep from "lodash/cloneDeep";
import { isEqual } from "lodash";

// CSS

import "./AthleteProfileEdit.css";

// Media

import CompanyLogo from "../../../../assets/logo.png";
import { ApiContext } from "../../../../context/ApiContext";
import { BASE_URL, BASE_URL_ADMIN } from "../../../../Utils/utils";
import axios from "axios";
import { Country } from "country-state-city";

// Global Variables

const API_ENDPOINT = BASE_URL;
// const API_ENDPOINT = "BASE_URL"

export default function ProfileAthleteEdit() {
  const { id } = useParams();
  const [step, setStep] = useState(1);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [username, setUsername] = useState("");

  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("athlete");

  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [profileImage, setUserImage] = useState("");

  const [phoneInternationalCode, setPhoneInternationalCode] = useState("");

  const [profileInfo, setProfileInfo] = useState({});
  const [athleteInfo, setAthleteInfo] = useState({});

  const [birthday, setBirthday] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [sport, setSport] = useState("");
  const [feets, setFeets] = useState("");
  const [inches, setInches] = useState("");
  const [weight, setWeight] = useState("");
  const [currentTeam, setCurrentTeam] = useState("");
  const [position, setPosition] = useState("");
  const [nationality, setNationality] = useState("");

  //
  const [youtube, setYoutube] = useState("");
  const [twitter, setTwitter] = useState("");
  const [tiktok, setTiktok] = useState("");

  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [highlights, setHighlights] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");

  // Statistics

  const [pts, setPts] = useState("");
  const [ast, setAst] = useState("");
  const [rpg, setRpg] = useState("");
  const [stl, setStl] = useState("");
  const [bpg, setBpg] = useState("");
  const navigate = useNavigate();
  const { setLoading } = useContext(ApiContext);

  useEffect(() => {
    // Fetch athlete information
    const fetchAthleteInfo = async () => {
      let profile = localStorage.getItem("profile");
      console.log(JSON.parse(profile));
      profile = JSON.parse(profile);

      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profile._id}/athlete`
        );

        const data = await response.json();
        console.log(data, "datadatadatadatadata");
        console.log("Fetch athlete Info");
        console.log(data);
        if (data.success && data.data) {
          // Set individual athlete info
          setSport(data.data.sport);
          setFeets(data.data.feets);
          setInches(data.data.inches);
          setWeight(data.data.weight);
          setCurrentTeam(data.data.currentTeam);
          setPosition(data.data.position);
          setAthleteInfo(data.data);
          setCountry(data.data.country)

          //

          //

          setPts(data.data.pts);
          setAst(data.data.ast);
          setRpg(data.data.rpg);
          setStl(data.data.stl);
          setBpg(data.data.bpg);
        }
      } catch (error) {
        console.error("Error fetching athlete info:", error);
      } finally {
        setLoading(false);
      }
    };

    async function fetchProfile() {
      let profile = localStorage.getItem("profile");
      console.log(JSON.parse(profile));
      profile = JSON.parse(profile);

      try {
        setLoading(true);

        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profile._id}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.success) {
          const profile = result.data;
          localStorage.setItem("profile", JSON.stringify(profile));
          const personItem = profile.people[0];
          if (updateStatus === "Update") {
            navigate("/profile/athlete");
          }
          setUpdateStatus("");
          console.log("Profile is:");
          console.log(personItem, "personItem");
          setProfileInfo(profile);
          setFirstName(personItem.firstname);
          setLastName(personItem.lastname);
          setEmail(profile.email);
          setUsername(profile.username);
          setHighlights(profile.highlights);
          setPhoneNumber(personItem.phone);
          setGender(personItem.gender);
          setNationality(personItem.nationality);
          setBirthday(profile.birthday || "");
          //setFeets(profile.height.feets);
          //setInches(profile.height.inches);
          setWeight(profile.weight);
          setCurrentTeam(profile.currentTeam);

          setUserImage(profile.profileImage);

          setPhoneInternationalCode(personItem.phoneInternationalCode || "");

          if (profile.links.length > 0) {
            profile.links.forEach((link) => {
              if (link.type === "youtube") {
                setYoutube(link.link);
              }

              if (link.type === "tiktok") {
                setTiktok(link.link);
              }

              if (link.type === "twitter") {
                setTwitter(link.link);
              }

              if (link.type === "website") {
                setWebsite(link.link);
              }

              if (link.type === "instagram") {
                setInstagram(link.link);
              }
            });
          }
        } else {
          console.error("Failed to fetch profile data:", result);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchProfile().then(fetchAthleteInfo);
  }, [id, updateStatus]);

  const UserComponent = ({ userData, setUserImage }) => {
    const placeholderImage =
      "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";

    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState(userData.image);
    const fileInputRef = useRef();

    const handleImageSelect = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedImage(URL.createObjectURL(file));
      }
    };

    const handleUpload = async (event) => {
      event.preventDefault(); // Prevent the default form submit action.
      const file = fileInputRef.current.files[0];
      if (!file) {
        console.log("No image selected!");
        return;
      }

      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", file);

      try {
        setLoading(true);
        const response = await axios.post(
          BASE_URL_ADMIN + "api/s3-upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const responseData = response.data;

        if (responseData.code === 200) {
          console.log("response data is ");
          console.log(responseData);
          setUploadedImageUrl(responseData.objectUrl);
          setUserImage(responseData.objectUrl);
          setSelectedImage(null);
          alert(
            "Upload with success. Now, to save it in your profile, you must press button 'Edit"
          );
        } else {
          throw new Error(responseData.message || "Upload failed");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Upload failed!");
      } finally {
        setIsLoading(false);
        setLoading(false);
        // setSelectedImage(null); // Clear the preview
      }
    };

    return (
      <div className="profile-image-wrapper">
        {isLoading ? (
          <div className="loader text-white">
            <h2>Loading...</h2>
          </div>
        ) : (
          <>
            <div style={{ display: "inline-block", position: "relative" }}>
              <label
                className="profile-image-wrapper-pen"
                htmlFor="fileUpload"
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faPen} />
              </label>

              <div className="profile-image-wrapper-pic">
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : uploadedImageUrl || placeholderImage
                  }
                  alt="$$$"
                />
                <input
                  type="file"
                  id="fileUpload"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                  ref={fileInputRef}
                />
              </div>
              <br></br>
            </div>

            <div style={{ marginBottom: "15px" }}>
              <div
                style={{ color: "#fff", fontSize: "1.5em", fontWeight: "bold" }}
                className="name"
              >
                {userData.name}
              </div>
              <div style={{ color: "#fff" }} className="nickname">
                @{userData.nickname}
              </div>
            </div>

            <div style={{ marginBottom: "15px" }}>
              {selectedImage != placeholderImage && (
                <button
                  className="mb-4 px-4 py-2 text-white"
                  style={{
                    background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                    borderRadius: "5px",
                  }}
                  onClick={handleUpload}
                >
                  Upload Image
                </button>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleBack = () => {
    if (step == 1) {
      alert("Back");
    }
    if (step > 1) setStep(step - 1);
  };

  const handleEditProfile = async (event) => {
    event.preventDefault();

    let profile = localStorage.getItem("profile");
    console.log(JSON.parse(profile));
    profile = JSON.parse(profile);

    let linksArray = [
      // Other social networks can be added just as YouTube, Twitter,
      // Tiktok, website, and Instagram have been added
      { socialNetwork: "youtube", link: youtube },
      { socialNetwork: "twitter", link: twitter },
      { socialNetwork: "tiktok", link: tiktok },
      { socialNetwork: "website", link: website },
      { socialNetwork: "instagram", link: instagram },
    ];

    const links = linksArray.reduce((acc, cur) => {
      if (cur.link) acc.push({ type: cur.socialNetwork, link: cur.link });
      return acc;
    }, []);

    let profileData = {
      title: firstName + lastName,
      type: userType,
      username,
      profileImage,
      links,
      highlights,
      email,
      people: [
        {
          firstname: firstName,
          lastname: lastName,
          gender,
          phone: phoneNumber,
          phoneInternationalCode,
          nationality: nationality,
        },
      ],
    };

    let athleteData = {
      sport,
      feets,
      inches,
      weight,
      currentTeam,
      position,
      nationality,
      country,
      pts,
      ast,
      rpg,
      stl,
      bpg,
    };

    const updatedProfileData = {};
    for (const [key, value] of Object.entries(profileData)) {
      if (profileInfo[key] !== value) {
        updatedProfileData[key] = value;
      }
    }

    const updatedAthleteData = {};
    for (const [key, value] of Object.entries(athleteData)) {
      if (athleteInfo[key] !== value) {
        updatedAthleteData[key] = value;
      }
    }

    console.log("Updated Athlete Data");
    console.log(updatedAthleteData);

    const updatedData = {
      profile: { _id: profileInfo._id, ...updatedProfileData },
      athlete: { _id: athleteInfo._id, ...updatedAthleteData },
    };

    // Send request only if there are changes
    if (
      Object.keys(updatedProfileData).length > 1 ||
      Object.keys(updatedAthleteData).length > 1
    ) {
      try {
        setLoading(true);

        const response = await fetch(
          API_ENDPOINT + `/profiles/id/${profile._id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData),
          }
        );

        if (response.ok) {
          // Handle the successful response accordingly

          alert("Profile and Athlete updated successfully.");
          setUpdateStatus("Update");
          // navigate("/profile/athlete");
          console.log(response);
          // localStorage.setItem("profile",JSON.stringify(response?.updatedModels?.profile))
        } else {
          // Handle errors
          console.error("Failed to update.");
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  console.log(country, "country");

  return (
    <>
      <Header />

      <Container>
        <Row>
          <Col xs={3}>
            <b></b>
          </Col>

          <Col xs={4}>
            <b></b>
          </Col>

          <Col xs={5} style={{ textAlign: "right" }}>
            <div
              className="text-white text-center"
              style={{
                lineHeight: "14px",
                padding: "10px",
                display: "inline-block",
                background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                borderRadius: "15px",
                cursor: "pointer",
              }}
            >
              <a
                href="/profile/link/edit"
                style={{
                  color: "#fff",
                  fontSize: "0.8em",
                  textDecoration: "none",
                }}
              >
                Edit<br></br>Scoutz Link
              </a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div
              className="text-white text-center"
              style={{
                lineHeight: "14px",
                padding: "10px",
                display: "inline-block",
                background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                borderRadius: "15px",
                cursor: "pointer",
              }}
            >
              <Link
                to="/profile/athlete"
                style={{
                  color: "#fff",
                  fontSize: "0.8em",
                  textDecoration: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ marginRight: "5px" }}
                />{" "}
                Back&nbsp;&nbsp;&nbsp;
              </Link>
            </div>
            <UserComponent
              setUserImage={setUserImage}
              userData={{
                image: profileImage,
                name: firstName,
                nickname: username,
              }}
            />

            <div
              className="row"
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <div className="col-12">
                <Button
                  variant="primary"
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(to right, rgb(159, 63, 134), rgb(27, 169, 206))",
                  }}
                  onClick={handleEditProfile}
                >
                  Save
                </Button>
              </div>
            </div>

            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
              />
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </Form.Group>

            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone number</Form.Label>

              <Row>
                <Col xs={3}>
                  <Form.Control
                    as="select"
                    value={phoneInternationalCode}
                    onChange={(e) => setPhoneInternationalCode(e.target.value)}
                  >
                    <option value="option">Select</option>
                    <option value="+54">Argentina (+54)</option>
                    <option value="+61">Australia (+61)</option>
                    <option value="+55">Brazil (+55)</option>
                    <option value="+86">China (+86)</option>
                    <option value="Canada+1">Canada (+1)</option>
                    <option value="+45">Denmark (+45)</option>
                    <option value="+358">Finland (+358)</option>
                    <option value="+33">France (+33)</option>
                    <option value="+49">Germany (+49)</option>
                    <option value="+91">India (+91)</option>
                    <option value="+39">Italy (+39)</option>
                    <option value="+81">Japan (+81)</option>
                    <option value="+64">New Zealand (+64)</option>
                    <option value="+31">Netherlands (+31)</option>
                    <option value="+234">Nigeria (+234)</option>
                    <option value="+47">Norway (+47)</option>
                    <option value="+48">Poland (+48)</option>
                    <option value="+351">Portugal (+351)</option>
                    <option value="+7">Russia (+7)</option>
                    <option value="+966">Saudi Arabia (+966)</option>
                    <option value="+27">South Africa (+27)</option>
                    <option value="+82">South Korea (+82)</option>
                    <option value="+34">Spain (+34)</option>
                    <option value="+46">Sweden (+46)</option>
                    <option value="+41">Switzerland (+41)</option>
                    <option value="+90">Turkey (+90)</option>
                    <option value="+44">United Kingdom (+44)</option>
                    <option value="+1">USA (+1)</option>
                  </Form.Control>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone number"
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <div className="row">
          <div className="col-12">
            <Form.Group controlId="formFirstName">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group controlId="formGender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                className="form-dropdown"
                value={gender}
                onChange={handleGenderChange}
              >
                <option value="" selected>
                  Select gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group controlId="formBirthday">
              <Form.Label>Birthday</Form.Label>
              <Form.Control
                type="text"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                placeholder="Birthday"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group controlId="formPosition">
              <Form.Label>Country</Form.Label>
              <Form.Control
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                as="select"
                className="form-dropdown"
              >
                <option value="" disabled>
                  Please select country
                </option>
                {Country.getAllCountries().map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group controlId="formSport">
              <Form.Label>Sport</Form.Label>
              <Form.Control
                type="text"
                value={sport}
                onChange={(e) => setSport(e.target.value)}
                placeholder="Sport"
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group controlId="formFeets">
              <Form.Label>Feet</Form.Label>
              <Form.Control
                type="text"
                value={feets}
                onChange={(e) => setFeets(e.target.value)}
                placeholder="Feets"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group controlId="formBirthday">
              <Form.Label>Inches</Form.Label>
              <Form.Control
                type="text"
                value={inches}
                onChange={(e) => setInches(e.target.value)}
                placeholder="Inches"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group controlId="formWeight">
              <Form.Label>Weight</Form.Label>
              <Form.Control
                type="text"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                placeholder="Weight"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-6">
            <div className="pt-4" style={{ color: "#fff" }}>
              lbs
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Form.Group controlId="formBirthday">
              <Form.Label>Current Team</Form.Label>
              <Form.Control
                type="text"
                value={currentTeam}
                onChange={(e) => setCurrentTeam(e.target.value)}
                placeholder="Current team"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group controlId="formPosition">
              <Form.Label>Position</Form.Label>
              <Form.Control
                as="select"
                className="form-dropdown"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              >
                <option value="">Select your Position</option>
                <option value="center">Center</option>
                <option value="pointguard">Point Guard</option>
                <option value="shootinguard">Shooting Guard</option>
                <option value="smallforwad">Small Forward</option>
                <option value="powerforward">Power Forward</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group controlId="formPosition">
              <Form.Label>Nationality</Form.Label>

              <Form.Control
                as="select"
                className="form-dropdown"
                value={nationality} // set the value to the state variable
                onChange={(e) => setNationality(e.target.value)} // update the state as the user makes a selection
              >
                <option value="">Select your Nationality</option>
                <option value="Asia">Asia</option>
                <option value="Africa">Africa</option>
                <option value="Europe">Europe</option>
                <option value="Australia">Australia</option>
                <option value="Canada">Canada</option>
                <option value="South America">South America</option>
                <option value="USA">USA</option>
                {/* Add additional options as necessary */}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>Highlights</Form.Label>
              <Form.Control
                type="text"
                value={highlights}
                onChange={(e) => setHighlights(e.target.value)}
                placeholder="Write your highlights URL"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        {/* Social Network */}

        <div className="row">
          <div className="col-12">
            <h2 style={{ color: "#fff" }}>Social Network</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>Twitter</Form.Label>
              <Form.Control
                type="text"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                placeholder="Write your username on Twitter"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>YouTube</Form.Label>
              <Form.Control
                type="text"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
                placeholder="Write your username on YouTube"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>TikTok</Form.Label>
              <Form.Control
                type="text"
                value={tiktok}
                onChange={(e) => setTiktok(e.target.value)}
                placeholder="Write your username on TikTok"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="text"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                placeholder="Write your Website URL"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>Instagram</Form.Label>
              <Form.Control
                type="text"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                placeholder="Write your username on Instagram"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        {/* Statistics */}

        <div className="row">
          <div className="col-12">
            <h2 style={{ color: "#fff" }}>Statistics</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group controlId="formWeight">
              <Form.Label style={{ color: "#D3D3D3" }}>PTS</Form.Label>
              <Form.Control
                type="text"
                value={pts}
                onChange={(e) => setPts(e.target.value)}
                placeholder="PTS"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group controlId="formWeight">
              <Form.Label>AST</Form.Label>
              <Form.Control
                type="text"
                value={ast}
                onChange={(e) => setAst(e.target.value)}
                placeholder="AST"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group controlId="formWeight">
              <Form.Label>RPG</Form.Label>
              <Form.Control
                type="text"
                value={rpg}
                onChange={(e) => setRpg(e.target.value)}
                placeholder="RPG"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group controlId="formWeight">
              <Form.Label>STL</Form.Label>
              <Form.Control
                type="text"
                value={stl}
                onChange={(e) => setStl(e.target.value)}
                placeholder="STL"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group controlId="formWeight">
              <Form.Label>BPG</Form.Label>
              <Form.Control
                type="text"
                value={bpg}
                onChange={(e) => setBpg(e.target.value)}
                placeholder="BPG"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>
      </Container>
    </>
  );
}
