import React, { useState, useEffect, useContext } from "react";

// Components

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
// import { Tab,Tabs } from 'react-bootstrap';

// Packages

import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
  Image,
  Card,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faBookBookmark,
  faArrowRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

// CSS

import "react-horizontal-scrolling-menu/dist/styles.css";
import "./Home.css";
import "./HamburguerMenu.css";

// Media

import Logo from "../../../assets/logo.png";
import CompanyLogo from "../../../assets/logo.png";
import PortugalImage from "../../../assets/cities/lisbon.jpg";
import SpainImage from "../../../assets/cities/madrid.jpg";
import ItalyImage from "../../../assets/cities/rome.jpg";
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL } from "../../../Utils/utils";
import { useMediaQuery } from "react-responsive";
import { ProfileLock, saveJobIcon } from "../../../assets";
import moment from "moment";

// Global Variables

const API_ENDPOINT = BASE_URL;

const Pulsate = keyframes`
  0% {
    transform: scale(0.95);
  }
  70% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(0.95);
  }
`;

const StyledImage = styled(Image)`
  animation: ${Pulsate} 1s ease-in-out infinite;
`;

// Teams

export function ScrollableTeamsRow({
  images,
  teamGender,
  mobileResponsive,
  setTeamGender,
  filterdataByGender,
}) {
  const completeUser = JSON.parse(localStorage.getItem("profile"));
  const [selected, setSelected] = React.useState([]);

  const navigate = useNavigate();
  const isItemSelected = (id) => !!selected.find((el) => el === id);
  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);
      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  const { t, i18n } = useTranslation();

  // const filterByGender = (type) => {
  //   const filterData = alljobs?.filter((item) => item?.gender == type);
  //   setJobs(filterData);
  // };

  return (
    <div className="col-12 d-flex flex-column mt-4 mb-4">
      <div className="flex justify-between items-center mb-3">
        <Row>
          <Col>
            <h1
              style={{ fontWeight: "bold", color: "white" }}
              className="text-2xl font-bold"
            >
              Pro Team Database
              {/* {t("recommended.Pro team Database")} */}
            </h1>
          </Col>
          <Col xs={3} className="text-end">
            <a
              // href="/teams/overview"
              onClick={() => navigate("/teams/overview")}
              style={{
                fontWeight: "300",
                color: "#1BA9CE",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {t("recommended.seeAll")}
            </a>
          </Col>
          <div style={{ display: "flex", gap: "10px" }}>
            {completeUser?.people?.[0]?.gender === "Male" && (
              <button
                className={`btn ${
                  teamGender == "Men" ? "btn-primary" : "border"
                } fw-bold fs-6  text-white rounded `}
                onClick={() => {
                  setTeamGender("Men");
                  filterdataByGender("Male");
                }}
              >
                Men
              </button>
            )}
            {completeUser?.people?.[0]?.gender === "Female" && (
              <button
                className={` btn ${
                  teamGender == "Women" ? "btn-primary" : "border"
                } fw-bold fs-6 text-white rounded`}
                onClick={() => {
                  setTeamGender("Women");
                  filterdataByGender("Female");
                }}
              >
                Women
              </button>
            )}
            {completeUser?.people?.[0]?.gender ? (
              ""
            ) : (
              <>
                <button
                  className={`btn ${
                    teamGender == "Men" ? "btn-primary" : "border"
                  } fw-bold fs-6  text-white rounded `}
                  onClick={() => {
                    setTeamGender("Men");
                    filterdataByGender("Male");
                  }}
                >
                  Men
                </button>

                <button
                  className={` btn ${
                    teamGender == "Women" ? "btn-primary" : "border"
                  } fw-bold fs-6 text-white rounded`}
                  onClick={() => {
                    setTeamGender("Women");
                    filterdataByGender("Female");
                  }}
                >
                  Women
                </button>
              </>
            )}
          </div>
          {/*
          <Col xs={3} className="text-end">
            <Link to="/teams/overview" style={{ fontWeight: '300', color:'#1BA9CE', textDecoration : 'none' }}>{t('recommended.seeAll')}</Link>
          </Col>
          */}
        </Row>
      </div>

      <div style={{ position: "relative", height: "100px", width: "100%" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            overflowX: "scroll",
          }}
          className="d-flex flex-row overflow-auto pb-4"
        >
          {images.map(({ id, src, title }, index) => (
            <TeamsTitle
              id={id}
              itemId={id}
              src={src}
              title={title}
              onClick={handleClick(id)}
              selected={isItemSelected(id)}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const ImageComponent = ({ src, fallbackSrc }) => {
  // Check if 'src' is provided, else use 'fallbackSrc'
  const imageUrl = src ? src : fallbackSrc;

  return (
    <img
      style={{
        borderRadius: "50%",
        margin: "1px auto",
        width: "60px",
        height: "60px",
        // background: `url(${imageUrl})`,
        // backgroundSize: "100%",
        // backgroundPosition: "center",
      }}
      src={imageUrl}
      alt="$$$"
    />
  );
};

function TeamsTitle({ itemId, src, title, index, ...restProps }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/teams/id/${itemId}`);
  };

  return (
    <div
      onClick={() =>
        restProps.onClick({ getItemById: undefined, scrollToItem: undefined })
      }
      style={{ width: "80px", margin: index === 0 ? "0 16px 0 8px" : "0 16px" }}
    >
      <Card
        onClick={handleNavigate}
        style={{
          cursor: "pointer",
          // width: "8rem",
          backgroundColor: "unset",
          border: "unset",
        }}
      >
        <ImageComponent
          src={src}
          // fallbackSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCifOFpkQ9QbQ_V-Tm1o1FPNpTyrTsHjVqoNCrUmqFxKkDe39qHGOwvbkOIO7ELAykm2Q&usqp=CAU"
        />

        <Card.Body style={{ textAlign: "center", padding: "0.25rem" }}>
          <Card.Title
            className="pt-1"
            style={{
              color: "#fff",
              fontSize: "0.65em",
              lineHeight: "1.2",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}

// Jobs

function JobsTitle({
  src,
  title,
  city,
  country,
  date,
  jobType,
  id,
  saved,
  jobGender,
}) {
  const [isSaved, setIsSaved] = useState(false);
  const [iconColor, setIconColor] = useState("initial");
  const [jobModalIsOpen, setJobModalIsOpen] = useState(false);

  const profile = JSON.parse(localStorage.getItem("profile"));

  const navigate = useNavigate();
  const { setLoading } = useContext(ApiContext);

  function handleNavigate(id) {
    navigate(`/jobs/id/${id}`);
  }
  const paymentMethods = profile?.paymentMethods;
  const lastPaymentMethod = paymentMethods?.[paymentMethods.length - 1];
  const subscriptionData = lastPaymentMethod?.subscription;

  const isExpiredOrCanceled =
    subscriptionData?.status === "incomplete_expired" ||
    subscriptionData?.status === "canceled";

  let isPastPeriodEnd = false;

  if (subscriptionData?.current_period_end) {
    const currentPeriodEndDate = new Date(
      subscriptionData.current_period_end * 1000
    );
    const currentDate = new Date();

    isPastPeriodEnd =
      currentDate.toISOString() > currentPeriodEndDate.toISOString();

    console.log(currentDate.toISOString(), currentPeriodEndDate.toISOString());
  }

  const payment =
    isExpiredOrCanceled && isPastPeriodEnd
      ? false
      : Boolean(subscriptionData?.id);

  const onClickHandler = (id, jobType) => () => {
    if (payment && (jobType === "exclusive" || jobType === "normal")) {
      navigate(`/jobs/id/${id}`);
    } else if (!payment && jobType === "normal") {
      navigate(`/jobs/id/${id}`);
    } else {
      setJobModalIsOpen(true);
    }
  };

  // Date formatting function
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const saveJob = async () => {
    // Check localStorage for the profile
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (!profile || !profile._id) {
      alert("Please log in to save the job.");
      return;
    }

    // Prepare the API endpoints
    const endpointToSave = `${API_ENDPOINT}/jobs/id/${id}/save`;
    const endpointToUnsave = `${API_ENDPOINT}/jobs/id/${id}/unsave`;

    // Define a helper to update the icon color and show an alert.
    const successHandler = (message, isSaved) => {
      alert(message);
      setIconColor(isSaved ? "aquamarine" : "initial");
    };

    try {
      setLoading(true);
      // We need "isSaved" state here to track if a job is saved or not.
      const response = await fetch(
        isSaved ? endpointToUnsave : endpointToSave,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ profileId: profile._id }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        // Toggle the isSaved state on success.
        setIsSaved(!isSaved);
        successHandler(data.message, !isSaved);
      } else {
        alert(data.message || "Failed to perform the operation.");
      }
    } catch (error) {
      alert("An error occurred. Please try again later.");
      console.error("Save job error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Effect to check if the job is saved when the component mounts or when _id changes.
    const checkIfJobIsSaved = () => {
      const profile = JSON.parse(localStorage.getItem("profile"));
      if (profile && profile._id) {
        const isJobSaved = saved.some((entry) => entry.profile === profile._id);
        setIsSaved(isJobSaved);
        setIconColor(isJobSaved ? "aquamarine" : "initial");
      }
    };
    checkIfJobIsSaved();
  }, [id]);

  const handleClose = () => {
    setJobModalIsOpen(false);
  };

  return (
    <>
      <Row
        style={{ position: "relative" }}
        onClick={onClickHandler(id, jobType)}
      >
        <Col
          style={
            jobType === "exclusive"
              ? {
                  filter: payment ? "blur(0px)" : "blur(10px)",
                  cursor: "pointer",
                  color: "#ffffff",
                }
              : { cursor: "pointer", color: "#ffffff" }
          }
          xs={3}
        >
          <Image
            src={src}
            style={{
              objectFit: "cover",
              width: "100%",
              aspectRatio: "1/1",
              borderRadius: 10,
              cursor: "pointer",
            }}
          />
        </Col>
        <Col
          xs={8}
          style={
            jobType === "exclusive"
              ? {
                  filter: payment ? "blur(0px)" : "blur(10px)",
                  cursor: "pointer",
                  color: "#ffffff",
                }
              : { cursor: "pointer", color: "#ffffff" }
          }
        >
          <div
            style={{
              borderRadius: "100px",
              padding: 3,
              color: "#fff",
              backgroundColor: "#1BA9CE",
              display: "inline-block",
              fontSize: "0.7em",
              paddingLeft: 10,
              paddingRight: 10,
              marginBottom: 4,
              cursor: "pointer",
            }}
          >
            Active
          </div>
          {jobGender && (
            <p
              style={{
                borderRadius: "100px",
                padding: 3,
                color: "#fff",
                backgroundColor: "rgba(159, 63, 134, 1)",
                display: "inline-block",
                fontSize: "0.7em",
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 4,
                marginLeft: "10px",
                cursor: "pointer",
              }}
            >
              {jobGender == "male" ? "Men" : "Women"}
            </p>
          )}
          {jobType === "exclusive" && (
            <p
              style={{
                borderRadius: "100px",
                padding: 3,
                color: "#fff",
                backgroundColor: "rgba(159, 63, 134, 1)",
                display: "inline-block",
                fontSize: "0.7em",
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 4,
                marginLeft: "10px",
                cursor: "pointer",
                textTransform: "capitalize",
              }}
            >
              {jobType}
            </p>
          )}

          <h1
            style={{ fontSize: "1.3em", fontWeight: "bold", cursor: "pointer" }}
          >
            {title}
          </h1>
          <p style={{ fontSize: "0.8em", opacity: "0.6", cursor: "pointer" }}>
            {city}, {country} - {moment(date).format("MM-DD-YYYY")}
          </p>
        </Col>
        <Col
          xs={1}
          // onClick={(e) => {
          //   e.stopPropagation();
          //   saveJob();
          // }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <img src={saveJobIcon} alt="$$$" />
          {/* <FontAwesomeIcon icon={faBookBookmark} style={{ color: iconColor }} /> */}
        </Col>

        {jobType === "exclusive" ? (
          payment ? null : (
            <div>
              <div className="exclusiveBlurLeft">
                <img src={ProfileLock} alt="$$$" />
              </div>
              <div className="exclusiveBlurRight">
                <h2 style={{ color: "#ffff" }}>Exclusive opening</h2>
              </div>
            </div>
          )
        ) : null}
      </Row>

      <Modal
        show={jobModalIsOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            Attention!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="attention">
            <p>
              sorry you must be on a subscription to view this exclusive opening
            </p>
            <div className="footerBtn">
              <button
                onClick={() => setJobModalIsOpen(false)}
                type="button"
                class="linkBtn"
              >
                Cancel
              </button>
              <button
                style={{
                  fontWeight: "bold",
                  color: "#FFF",
                  height: "45px",
                  border: "none",
                  borderRadius: "15px",
                  width: "100%",
                  background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                  padding: "10px 40px",
                }}
                onClick={() => navigate("/settings/paymentmethods")}
              >
                Subscribe
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function ScrollableJobsRow({ images }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <Row>
          <Col xs={9}>
            <h1
              style={{ fontWeight: "bold", color: "white" }}
              className="text-2xl font-bold"
            >
              Job Openings
              {/* {t("recommended.jobs")} */}
            </h1>
          </Col>
          <Col xs={3} className="text-end">
            <a
              // href="/jobs/overview"
              onClick={() => navigate("/jobs/overview")}
              style={{
                fontWeight: "300",
                color: "#1BA9CE",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {t("recommended.seeAll")}
            </a>
          </Col>
        </Row>
      </div>

      <Row>
        {images.slice(0, 5).map((img, index) => (
          <JobsTitle key={index} navigate={navigate} {...img} />
        ))}
      </Row>

      {/* <a target="_blank" href="https://scoutzagent.kartra.com/page/R1Q16">
        <div className="gradient-border">
          <div className="inner-div">{t("home.book11")}</div>
        </div>
      </a> */}
    </>
  );
}

// News

function NewsTitle({ id, src, title, city, country, date }) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/news/id/${id}`);
  };

  // Date formatting function
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <Row onClick={handleNavigate}>
        <Col xs={3}>
          <Image
            src={src}
            style={{
              objectFit: "cover",
              width: "100%",
              aspectRatio: "1/1",
              borderRadius: 10,
            }}
          />
        </Col>
        <Col xs={8} style={{ color: "#ffffff" }}>
          <h1 style={{ fontSize: "1.1em", fontWeight: "bold" }}>{title}</h1>
          <p style={{ fontSize: "0.8em", opacity: "0.6" }}>
            {city}, {country} - {formatDate(date)}
          </p>
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          {/*<FontAwesomeIcon icon={faBookBookmark} />*/}
        </Col>
      </Row>
    </>
  );
}

export function ScrollableNewsRow({ images }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="d-flex justify-content-between mt-4 mb-2">
        <h3 style={{ fontWeight: "bold", color: "white" }}>
          {t("modules.news")}
        </h3>
      </div>
      <Row style={{ marginBottom: "70px" }}>
        F
        {images.map((img, index) => (
          <NewsTitle key={index} {...img} />
        ))}
      </Row>
    </>
  );
}

// Scoutz Pro Day

const ScoutzProDay = () => {
  const [teams, setTeams] = useState([]);
  const [teamsLoaded, setTeamsLoaded] = useState(false);
  const { setLoading } = useContext(ApiContext);
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}/events/type/scoutzproday`
        );
        const data = await response.json();

        setTeams(data.data);
        setTeamsLoaded(true);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  const navigate = useNavigate();

  const handleNavigate = (id) => {
    //navigate(`/proday/id/${id}`);
  };

  const { t, i18n } = useTranslation();
  return (
    <div className="text-white mt-4">
      <div className="flex justify-between items-center mb-3">
        <Row>
          <Col xs={9}>
            <h1
              style={{ fontWeight: "bold", color: "white" }}
              className="text-2xl font-bold"
            >
              Scoutz Pro Day
            </h1>
          </Col>
          <Col xs={3} className="text-end pt-2">
            <a
              href="/proday"
              className="mt-4"
              style={{
                fontWeight: "300",
                color: "#1BA9CE",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {t("recommended.seeAll")}
            </a>
          </Col>
        </Row>
      </div>

      {teamsLoaded && teams.length > 0 && (
        <div key={0} className="mb-6">
          <img
            src={teams[0].imageUrl}
            alt="Group of people at Scoutz Pro Day event"
            style={{ width: "100%" }}
            className="mb-4 rounded-lg"
          />
          <h2 className="text-lg font-bold mb-4">{teams[0].title}</h2>
          {/* <p className="text-sm mb-4">{new Date(teams[0].time).toLocaleDateString()}</p> */}
          <a href="/proday">
            <button className="bg-blue-500 text-white btn-signin mt-0 rounded-lg w-full">
              Register here
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

// Home Page

function Home() {
  const completeUser = JSON.parse(localStorage.getItem("profile"));
  const navigate = useNavigate();
  const { setLoading } = useContext(ApiContext);

  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [gender, setGender] = useState("");
  const [teamGender, setTeamGender] = useState("");
  const [teamsLoaded, setTeamsLoaded] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [alljobs, setAllJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);

  const [newsArticles, setNewsArticles] = useState([]);
  const [newsLoaded, setNewsLoaded] = useState(false);

  const { t, i18n } = useTranslation();

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const selectGender = (item) => {
    setGender(item);
  };

  const filterByGender = (type) => {
    const filterData = alljobs?.filter(
      (item) => item?.gender?.toLowerCase() == type?.toLowerCase()
    );
    setJobs(filterData);
  };

  const filterTeamByGender = (type) => {
    const filterData = allTeams?.filter((item) => item?.gender == type);
    setTeams(filterData);
  };

  // Load Teams
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        setLoading(true);

        const response = await fetch(`${API_ENDPOINT}/profiles/type/teams`);
        const data = await response.json();
        const docsWithGender = data.data.docs.map((doc) => {
          // Add a gender property with a random value
          const gender = Math.random() < 0.8 ? "Male" : "Female";
          return {
            ...doc,
            gender: gender,
          };
        });
        if (completeUser?.people?.[0]?.gender) {
          const filterData = docsWithGender?.filter(
            (item) => item?.gender == completeUser?.people?.[0]?.gender
          );
          setTeams(filterData);
          setAllTeams(filterData);
        } else {
          setTeams(docsWithGender);
          setAllTeams(docsWithGender);
        }

        setTeamsLoaded(true);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  // Load Recommended Jobs
  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        setLoading(true);

        const response = await fetch(`${API_ENDPOINT}/profiles/jobs?gender=`);
        const data = await response.json();

        if (completeUser?.people?.[0]?.gender) {
          const filterData = data.data.docs?.filter((item) =>
            item?.gender
              ? item?.gender.toLowerCase() ==
                completeUser?.people?.[0]?.gender.toLowerCase()
              : null
          );
          setJobs(filterData);
          setAllJobs(filterData);
        } else {
          setJobs(data.data.docs);
          setAllJobs(data.data.docs);
        }
        setJobsLoaded(true);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobsData();
  }, []);

  useEffect(() => {
    if (completeUser?.people?.[0]?.gender) {
      selectGender(
        completeUser?.people?.[0]?.gender?.toLowerCase() == "male"
          ? "Men"
          : "Women"
      );
      setTeamGender(
        completeUser?.people?.[0]?.gender === "Male" ? "Men" : "Women"
      );
    }
  }, [completeUser]);

  // Load News
  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        setLoading(true);

        const response = await fetch(`${API_ENDPOINT}/news`);
        const data = await response.json();

        setNewsArticles(data.data.docs);

        setNewsLoaded(true);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
  }, []);

  let token = localStorage.getItem("token");

  return (
    <>
      <Header />

      <Container
        className="create-account-container"
        style={{ paddingBottom: "30px" }}
      >
        {/* Recommended Teams */}

        <div className={token ? "" : "recommended-teams"}>
          {teamsLoaded && teams.length > 0 && (
            <ScrollableTeamsRow
              images={teams.map((team) => ({
                src: team.profileImage,
                title: team.title,
                id: team._id,
              }))}
              teamGender={teamGender}
              mobileResponsive={mobileResponsive}
              setTeamGender={setTeamGender}
              filterdataByGender={filterTeamByGender}
            />
          )}
        </div>

        {/* Recommended Teams */}
        <div className="d-flex justify-content-start gap-2 mt-5">
          {completeUser?.people?.[0]?.gender === "Male" && (
            <button
              className={`btn ${
                gender == "Men" ? "btn-primary" : "border"
              } fw-bold fs-6  text-white rounded `}
              onClick={() => {
                selectGender("Men");
                filterByGender("Male");
              }}
            >
              Men
            </button>
          )}
          {completeUser?.people?.[0]?.gender === "Female" && (
            <button
              className={` btn ${
                gender == "Women" ? "btn-primary" : "border"
              } fw-bold fs-6 text-white rounded`}
              onClick={() => {
                selectGender("Women");
                filterByGender("Female");
              }}
            >
              Women
            </button>
          )}
          {completeUser?.people?.[0]?.gender ? (
            ""
          ) : (
            <>
              <button
                className={`btn ${
                  gender == "Men" ? "btn-primary" : "border"
                } fw-bold fs-6  text-white rounded `}
                onClick={() => {
                  selectGender("Men");
                  filterByGender("Male");
                }}
              >
                Men
              </button>

              <button
                className={` btn ${
                  gender == "Women" ? "btn-primary" : "border"
                } fw-bold fs-6 text-white rounded`}
                onClick={() => {
                  selectGender("Women");
                  filterByGender("Female");
                }}
              >
                Women
              </button>
            </>
          )}
        </div>
        {jobsLoaded && jobs.length > 0 && (
          <ScrollableJobsRow
            images={jobs.map((job) => ({
              src: job?.image ? job?.image : job.profile.profileImage,
              id: job._id,
              title: job.title,
              city: job.city,
              country: job.country,
              date: job.createdAt,
              saved: job.saved,
              jobGender: job?.gender,
              jobType: job?.jobType,
            }))}
            setGender={setGender}
          />
        )}

        {/* Scoutz Pro Day and Active */}

        {/* <div className="text-white mt-4">
          <ScoutzProDay />
        </div> */}

        {/* Recommended News */}

        {newsLoaded && newsArticles.length > 0 && (
          <ScrollableNewsRow
            images={newsArticles
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((article) => ({
                src: article.highlightImage,
                id: article._id,
                title: article.title,
                city: "Basketball",
                date: article.createdAt,
              }))}
          />
        )}

        <div style={{ height: "70px" }}></div>
      </Container>

      <Footer />
    </>
  );
}

export default Home;
