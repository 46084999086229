import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ApiContext } from "../../context/ApiContext";
import { BASE_URL_ADMIN } from "../../Utils/utils";
import {
  BellIcon,
  CoachIcon,
  DefaultJob,
  deleteIcon,
  editIcon,
  saveJobIcon,
} from "../../assets";
import NewHeader from "../components/newHeader";
import { useNavigate } from "react-router-dom";
import "./coachJobs.css";
import BlueButton from "../../Component/UI/BlueButton";
import BorderButton from "../../Component/UI/BorderButton";
import { useTranslation } from "react-i18next";
import PlayerCard from "../../Component/UI/PlayerCard";
import CoachFooter from "../components/CoachFooter/CoachFooter";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CoacHJobs = () => {
  const { setLoading } = useContext(ApiContext);
  let profile = localStorage.getItem("profile");
  const [profileData, setProfileData] = useState(null);
  const [allJObs, setAllJObs] = useState([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  profile = JSON.parse(profile);

  console.log(profile?._id,"profile?._id")

  const fetchJobsData = async () => {
    try {
      const response = await axios.get(`${BASE_URL_ADMIN}jobs`);
      const data = response.data?.data?.docs?.filter(
        (item) => item?.website == profile?._id
      );
      setAllJObs(data);
      console.log(data, "setAllJobs");
    } catch (error) {
      console.error("Error fetching jobs data:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchJobsData();
    setProfileData(profile);
  }, []);

  const UserComponent = ({ userData }) => {
    const placeholderImage = CoachIcon;

    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(
      userData.image || placeholderImage
    );

    console.log("User image is ");
    console.log(userData.image);

    const deleteJob = async (id) => {
      try {
        const response = await axios.delete(
          `${BASE_URL_ADMIN}jobs/delete-job/${id}`,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 204) {
          fetchJobsData();
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Failed to update the job. Please try again.");
      }
    };

    return (
      <div className="coachJobs coachContainer">
        {/* <NewHeader name={"Coach profile"} path="/home" /> */}
        <div className="coachJobContainer">
          <div className="coachJobsLeft">
            <div className="coachImage">
              <img alt="$$$" src={selectedImage} style={{ margin: 0 }} />
            </div>
            <div>
              <div className="coachJobNameTitle">Hello coach,</div>
              <div className="nickname text-white">{userData.name}</div>
            </div>
          </div>
          <div className="coachJobBellIcon">
            <img src={BellIcon} style={{ margin: 0 }} />
          </div>
        </div>

        <div className="coachJobFilter">
          <h1>My Jobs</h1>

          <div className="filters">
            <BlueButton active={true}>All</BlueButton>
            <BlueButton>Open</BlueButton>
            <BlueButton>Closed</BlueButton>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px", overflowX: "auto" }}>
          {allJObs.map((item, index) => {
            return (
              <div className="coachJobSingleContainer" key={index}>
                <div
                  
                  className="coachJobsLeft"
                >
                  <div className="coachImage">
                    <img
                      alt="$$$"
                      src={item?.image || DefaultJob}
                      style={{ margin: 0 }}
                    />
                  </div>
                  <div>
                    <div className="nickname text-white">
                      {item?.title || userData.name}
                    </div>
                    <div
                      className="coachJobNameTitle"
                      style={{ color: "rgba(181, 181, 181, 1)" }}
                    >
                      title . {moment(item?.createdAt).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </div>
                <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
                  <div
                    onClick={() => deleteJob(item?._id)}
                    className="coachJobBellIcon"
                  >
                    {/* <FontAwesomeIcon icon="fa-solid fa-trash" /> */}
                    <img width={"30%"} src={deleteIcon} style={{ margin: 0 }} />
                  </div>
                  <div
                    onClick={() =>
                      navigate("/edit-coach-job", { state: { data: item } })
                    }
                    className="coachJobBellIcon"
                  >
                    {/* <FontAwesomeIcon icon="fa-solid fa-trash" /> */}
                    <img width={"40%"} src={editIcon} style={{ margin: 0 }} />
                  </div>

                  <div
                    onClick={() =>
                      navigate("/job-applied-playes", { state: { data: item } })
                    }
                    style={{color:"#fff",fontWeight:"700",cursor:"pointer"}}
                  >
                    {/* <FontAwesomeIcon icon="fa-solid fa-trash" /> */}
                    {/* <img width={"40%"} src={editIcon} style={{ margin: 0 }} /> */}
                    {"View >>"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Container
        className="create-account-container profileCoach coachJobs"
        style={{
          paddingBottom: "150px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        {profileData && (
          <div>
            <UserComponent
              userData={{
                image: profileData?.profileImage,
                name: profileData?.title,
                nickname: profileData?.username,
              }}
            />
          </div>
        )}

        <div style={{ padding: "10px" }}>
          <BorderButton onClick={() => navigate("/coach-post-job")}>
            Post a job
          </BorderButton>

          <Row style={{ marginTop: "20px" }}>
            <Col xs={9}>
              <h1
                style={{ fontWeight: "bold", color: "white" }}
                className="text-2xl font-bold"
              >
                Top Scouts
                {/* {t("recommended.jobs")} */}
              </h1>
            </Col>
            <Col xs={3} className="text-end">
              <a
                // href="/jobs/overview"
                onClick={() => navigate("/jobs/overview")}
                style={{
                  fontWeight: "300",
                  color: "#1BA9CE",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {t("recommended.seeAll")}
              </a>
            </Col>
            <Col xs={12}>
              <p style={{ color: "#fff" }}>
                Connect get help recruiting from vatious trusted Scouts/agents
              </p>
            </Col>
            <div style={{ display: "flex", gap: "20px", overflowX: "auto" }}>
              {[1, 2, 3, 4]?.map((item, index) => {
                return (
                  <Col key={index} xs={6}>
                    <PlayerCard />
                  </Col>
                );
              })}
            </div>
          </Row>
        </div>

        <div style={{ padding: "10px" }}>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={9}>
              <h1
                style={{ fontWeight: "bold", color: "white" }}
                className="text-2xl font-bold"
              >
                Players
              </h1>
            </Col>
            <Col xs={3} className="text-end">
              <a
                onClick={() => navigate("/jobs/overview")}
                style={{
                  fontWeight: "300",
                  color: "#1BA9CE",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {t("recommended.seeAll")}
              </a>
            </Col>

            <div style={{ display: "flex", gap: "20px", overflowX: "auto" }}>
              {[1, 2, 3, 4]?.map((item, index) => {
                return (
                  <Col key={index} xs={6}>
                    <PlayerCard />
                  </Col>
                );
              })}
            </div>
          </Row>
        </div>
      </Container>

      <CoachFooter />
    </>
  );
};

export default CoacHJobs;
