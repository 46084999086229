/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useContext } from "react";

// Components

import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";

// Packages

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Player, PosterImage } from "video-react";
import {
  faAirFreshener,
  faBookBookmark,
  faGlobe,
  faEye,
  faSearch,
  faArrowRight,
  faArrowLeft,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
  Image,
  Card,
} from "react-bootstrap";
import Chart from "react-apexcharts";
import "video-react/dist/video-react.css";
import "./athlete.css";

// Media

import Twitter from "../../../../assets/icons/socialmedia/twitter.png";
import Instagram from "../../../../assets/icons/socialmedia/instagram.png";
import TikTok from "../../../../assets/icons/socialmedia/tiktok.png";
import Web from "../../../../assets/icons/socialmedia/web.png";
import YouTube from "../../../../assets/icons/socialmedia/youtube.png";
import { ApiContext } from "../../../../context/ApiContext";
import { BASE_URL } from "../../../../Utils/utils";
import { CoachIcon, Menplayer, Womenplayer } from "../../../../assets";
// import '~video-react/dist/video-react.css';

// Global Variables

const API_ENDPOINT = BASE_URL;
// const API_ENDPOINT = "BASE_URL"

/*



  START COMPONENTS



*/

/*

  Scoutz Link - Show Portifolio - button component

*/



const UserComponent = ({ userData,profileData }) => {

  const placeholderImage = profileData?.profileImage
  ? profileData?.profileImage
  : profileData?.type === "coach"
  ? CoachIcon
  : profileData?.people[0]?.gender?.toLowerCase() === "male"
  ? Menplayer
  : Womenplayer;
  // const placeholderImage = profileData?.type === "coach" ? CoachIcon : profileData?.people[0]?.gender?.toLowerCase() === "male" ?  Menplayer : Womenplayer 

  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    userData.image || placeholderImage
  );
  const [profileImage, setProfileImage] = useState(userData.image);

  console.log("User image is ");
  console.log(userData.image);
  // Function to handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    // Set loading state while the image is being uploaded
    setIsLoading(true);

    reader.onloadend = () => {
      // Set the profile image once the file is loaded
      setProfileImage(reader.result);
      // Reset loading state after image is uploaded
      setIsLoading(false);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="profile-image-wrapper">
      {isLoading ? (
        <div className="loader" style={{ color: "#fff" }}>
          Loading...
        </div>
      ) : (
        <div style={{ display: "inline-block", position: "relative" }}>
          {/* <Link to="/profile/athlete/edit">
            <label className="profile-image-wrapper-pen" style={{ cursor: 'pointer', bottom: 0 }}>
              <FontAwesomeIcon icon={faPen} />
            </label>
          </Link> */}
          <img
            alt="$$$"
            src={selectedImage}
            className="profile-image-wrapper-pic"
          />
        </div>
      )}
      <div
        style={{ fontSize: "1.2em", fontWeight: "bold" }}
        className="name text-white mt-3"
      >
        {userData.name}
      </div>
      <div className="nickname text-white mt-1">@{userData.nickname}</div>
    </div>
  );
};

/*

  Display followers and who is following component

*/

const FollowersFollowing = ({ followers, following }) => {
  //const [followers, setFollowers] = useState(20000);

  return (
    <Row style={{ textAlign: "center", color: "#fff" }}>
      <Col xs={6} className="border-right">
        <h2 className="font-weight-bold">{followers}</h2>
        <p className="text-white-50">Followers</p>
      </Col>
      <Col xs={6}>
        <h2 className="font-weight-bold">{following}</h2>
        <p className="text-white-50">Following</p>
      </Col>
    </Row>
  );
};

/*

  Social Media links component

*/

const SocialMedia = ({ profileData }) => {
  // Filter out links with empty or invalid URLs
  const validLinks = profileData.links.filter((link) => link.link && link.type);

  return (
    <Col xs={12} className="text-center mb-1">
      {validLinks.map(({ type, link, _id }) => {
        let imgSrc;
        let constructedLink = "";
        switch (type) {
          case "twitter":
            imgSrc = Twitter;
            constructedLink = `https://twitter.com/${link}`;
            break;
          case "instagram":
            imgSrc = Instagram;
            constructedLink = `https://www.instagram.com/${link}`;
            break;
          case "tiktok":
            imgSrc = TikTok;
            constructedLink = `https://www.tiktok.com/@${link}`;
            break;
          case "website":
            imgSrc = Web;
            constructedLink = link;
            break;
          case "youtube":
            imgSrc = YouTube;
            constructedLink = `https://www.youtube.com/@${link}`;
            break;
          default:
            // Default image in case the type doesn't match
            imgSrc = Web;
            break;
        }
        return (
          <div
            key={_id}
            className="mr-3"
            style={{ display: "inline-block", paddingRight: "15px" }}
          >
            <Link to={ type==="website" ? `https://${constructedLink}`: constructedLink} target="_blank" rel="noreferrer">
              <img src={imgSrc} width="30" alt={type} />
            </Link>
          </div>
        );
      })}
    </Col>
  );
};

/*

  Who Viewed My Profile component

*/

const ProfileViews = ({ profileData }) => {
  const [viewsCount, setViewsCount] = useState(0);
  const [searchCount, setSearchCount] = useState(0);

  const navigate = useNavigate();

  const { _id, profile, createdAt, views } = profileData;

  console.log(views);

  const handleProfileRedirect = () => {
    // navigate to another page
    navigate("/profile/whoviewed");
  };

  useEffect(() => {
    setViewsCount(views.length);
  }, [views]);

  return (
    <div className="mb-4 px-2">
      <Col className="btn-edit1" xs={1} style={{ textAlign: "right" }}></Col>
      <Col className="btn-edit" xs={5} style={{ textAlign: "right" }}></Col>
      <div
        style={{
          backgroundColor: "#3A3A3A",
          borderRadius: "15px",
          marginBottom: "15px",
        }}
      >
        <Row
          className="px-4"
          style={{ paddingLeft: "15px", paddingRight: "15px", color: "#fff" }}
        >
          <Col
            xs={12}
            onClick={handleProfileRedirect}
            style={{
              position: "relative",
              height: "50px",
              lineHeight: "50px",
              borderBottom: "1px solid #fff",
            }}
          >
            <FontAwesomeIcon className="mr-2" icon={faEye} /> {viewsCount}{" "}
            Profile Views
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ position: "absolute", right: 15, top: 15 }}
            />
          </Col>
        </Row>

        <Row
          className="px-4"
          style={{ paddingLeft: "15px", paddingRight: "15px", color: "#fff" }}
        >
          <Col
            xs={12}
            onClick={handleProfileRedirect}
            style={{ position: "relative", height: "50px", lineHeight: "50px" }}
          >
            <FontAwesomeIcon className="mr-2" icon={faSearch} /> {searchCount}{" "}
            Search Views
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ position: "absolute", right: 15, top: 15 }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

/*

  Chart component

*/

function AthleteChart({ athleteData }) {
  console.log("Athlete Data is ");
  console.log(athleteData);

  // Check if athleteData object is empty or not
  if (!athleteData || Object.keys(athleteData).length === 0) {
    return <div>Data is loading...</div>;
  }

  const options = {
    chart: {
      id: "athlete-chart",
      background: "#00000",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#D3D3D3"],
        fontWeight: [600],
      },
    },
    // series:{
    //   data:["haider","Ali","ajj","ahjsj"],

    // },
    // yaxis:{

    //   labels:{
    //   // categories:["haider","Ali","ajj","ahjsj"],
    //     style:{
    //       colors:["#fff"],
    //       textShadow: ["5px 5px 9px #FF0000"],

    //     }
    //   }
    // },
    yaxis: {
      title: {
        text: "Series",
      },
      min: 0,
    },
    xaxis: {
      title: {
        text: "Categories",
      },
      categories: ["PTS", "AST", "RPG", "STL", "BPG"],
      labels: {
        style: {
          colors: ["#D3D3D3"],
          textShadow: ["5px 5px 9px #FF0000"],
          fontWeight: [600],
        },
      },
    },

    fill: {
      colors: ["#8B3976"],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      showForSingleSeries: true,
      colors: ["#8B3976"],
      markers: {
        fillColors: ["#763164"],
      },
    },
  };

  function getNumber(value) {
    const parsed = String(value);
    return isNaN(parsed) ? 0 : parsed;
  }

  // Here we sanitize the series data by calling getNumber on everything
  const series = [
    {
      name: "Catergories",
      data: [
        getNumber(athleteData.pts),
        getNumber(athleteData.ast),
        getNumber(athleteData.rpg),
        getNumber(athleteData.stl),
        getNumber(athleteData.bpg),
      ],
    },
  ];

  return (
    <div className="mt-4">
      <div className="flex justify-between items-center mb-2">
        <h1
          style={{ fontWeight: "bold", color: "white" }}
          className="text-xl font-bold"
        >
          Statistics
        </h1>
      </div>
      <Row>
        <Col xs={12}>
          <Chart options={options} series={series} type="bar" width="100%" />
        </Col>
      </Row>
    </div>
  );
}

function ProfileStatistics({ profileData }) {
  console.log(profileData);

  function getReadablePosition(position) {
    switch (position) {
      case "center":
        return "Center";
      case "pointguard":
        return "Point Guard";
      case "shootinguard":
        return "Shooting Guard";
      case "smallforwad":
        return "Small Forward";
      case "powerforward":
        return "Power Forward";
      default:
        return "N/A";
    }
  }

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h1
          style={{ fontWeight: "bold", color: "white" }}
          className="text-xl font-bold"
        >
          Profile Summary
        </h1>
      </div>

      <Row
        className="mb-4"
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          color: "#fff",
          textAlign: "center",
          marginBottom: "5px",
        }}
      >
        <Col xs={4}>
          <div
            className="mb-2"
            style={{ fontSize: "0.9em", fontWeight: "300" }}
          >
            Height
          </div>
          <b>{profileData.feets || "N/A"}"</b>{" "}
          <b>{profileData.inches || "N/A"}</b>
        </Col>
        <Col xs={4}>
          <div
            className="mb-2"
            style={{ fontSize: "0.9em", fontWeight: "300" }}
          >
            Weight
          </div>
          <b>{profileData.weight || "N/A"}</b>
        </Col>
        <Col xs={4}>
          <div
            className="mb-2"
            style={{ fontSize: "0.9em", fontWeight: "300" }}
          >
            Country
          </div>
          <b>{profileData.country || "N/A"}</b>
        </Col>
      </Row>

      <Row
        className="mb-4"
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Col xs={4}>
          <div
            className="mb-2"
            style={{ fontSize: "0.9em", fontWeight: "300" }}
          >
            Level
          </div>
          <b>{profileData.level || "N/A"}</b>
        </Col>
        <Col xs={4}>
          <div
            className="mb-2"
            style={{ fontSize: "0.9em", fontWeight: "300" }}
          >
            Current Team
          </div>
          <b>{profileData.currentTeam || "N/A"}</b>
        </Col>
        <Col xs={4}>
          <div
            className="mb-2"
            style={{ fontSize: "0.9em", fontWeight: "300" }}
          >
            Position
          </div>
          <b>{getReadablePosition(profileData.position) || "N/A"}</b>
        </Col>
      </Row>
    </>
  );
}

/*

  Instagram highlights component

*/

function InstagramHighlight({ src, title }) {
  return (
    <Card
      style={{
        width: "12rem",
        marginRight: "16px",
        backgroundColor: "unset",
        border: "unset",
      }}
    >
      <div
        style={{
          borderRadius: "15px",
          margin: "5px auto",
          height: "250px",
          width: "100%",
          backgroundImage: `url(${src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Card.Body style={{ textAlign: "left", padding: 0 }}>
        <Card.Title style={{ color: "#fff", fontSize: "0.8em" }}>
          {title}
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

function ScrollableInstagramPhotosRow({ images }) {
  return (
    <>
      <div className="d-flex mt-4 mb-4">
        <h3 style={{ fontWeight: "bold", color: "white" }}>Instagram Photos</h3>
      </div>
      <div className="d-flex flex-row overflow-auto pb-4">
        {images.map((img, index) => (
          <InstagramHighlight key={index} {...img} />
        ))}
      </div>
    </>
  );
}

/*

  Highlights component

*/

function Highlight({ videoUrl }) {
  // Function to convert a YouTube video URL into an embed URL
  const getEmbedUrl = (url) => {
    console.log(url, "ur;;;;;;;;;;;;;;;;;;;;");
    if (url.includes("youtube.com/shorts/")) {
      return url.replace("/shorts/", "/embed/");
    } else if (url.includes("youtube.com/watch?v=")) {
      return url.replace("watch?v=", "embed/");
    } else if (url.includes("youtu.be/")) {
      return url.replace("youtu.be/", "youtube.com/embed/");
    }
    return url; // fallback in case the URL is already an embed URL or doesn't match expected patterns
  };

  const embedUrl = getEmbedUrl(videoUrl);

  return (
    <Card
      style={{
        width: "100%",
        marginRight: "16px",
        backgroundColor: "unset",
        border: "unset",
      }}
    >
      <iframe
        width="100%"
        height="400px"
        src={embedUrl}
        title="video"
        frameBorder="0"
        style={{ borderRadius: "15px", margin: "5px auto" }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      {/* <Card.Body style={{ textAlign: "left", padding: 0 }}>
        <Card.Title style={{ color: "#fff", fontSize: "0.8em" }}>
          {title}
        </Card.Title>
      </Card.Body> */}
    </Card>
  );
}

function ScrollableHighlightsRow({ images }) {
  return (
    <>
      <div
        style={{ width: "100%", textAlign: "start" }}
        className="d-flex mt-4 mb-4"
      >
        <h3
          style={{
            fontWeight: "bold",
            color: "white",
            width: "100%",
            textAlign: "start",
          }}
        >
          Highlights
        </h3>
      </div>
      <div
        style={{ width: "100%" }}
        className="d-flex flex-row overflow-auto pb-4"
      >
        {images.map((videoUrl, index) => (
          <Highlight key={index} videoUrl={videoUrl.src} />
        ))}
      </div>
    </>
  );
}

/*

  News component

*/

const NewsColumn = ({ src, title, city, country, date }) => (
  <>
    <Col xs={3} style={{ textAlign: "center" }}>
      <Image
        src={src}
        style={{
          objectFit: "cover",
          width: "100%",
          aspectRatio: "1/1",
          borderRadius: "15px",
        }}
      />
    </Col>
    <Col xs={8} style={{ color: "#ffffff" }}>
      <h1 style={{ fontSize: "1em", fontWeight: "bold" }}>{title}</h1>
      <p style={{ fontSize: "0.8em", opacity: "0.6" }}>
        {city}, {country} - {date}
      </p>
    </Col>
    <Col
      xs={1}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
      }}
    >
      {/*<FontAwesomeIcon icon={faBookBookmark} />*/}
    </Col>
  </>
);

function ScrollableNewsRow({ images }) {
  return (
    <>
      <div className="d-flex justify-content-between mt-4 mb-4">
        <h3 style={{ fontWeight: "bold", color: "white" }}>Trending</h3>
      </div>
      <Row>
        {images.map((img, index) => (
          <NewsColumn key={index} {...img} />
        ))}
      </Row>
    </>
  );
}

/*



  START PAGE



*/

function MyProfileAthlete() {
  const navigate = useNavigate();
  const [menuDisplay, setMenuDisplay] = useState(false);

  const [followers, setFollowers] = useState(0);
  const [following, setFollowing] = useState(0);
  const [profileImage, setProfileImage] = useState("");
  // const placeholderImage = "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";

  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [profileData, setProfileData] = useState("");
  const [athleteData, setAthleteData] = useState("");

  const handleOnOpen = () => {
    setMenuDisplay(true);
  };

  const [pts, setPts] = useState("");
  const [ast, setAst] = useState("");
  const [rpg, setRpg] = useState("");
  const [stl, setStl] = useState("");
  const [bpg, setBpg] = useState("");

  const [sport, setSport] = useState("");
  const [feets, setFeets] = useState("");
  const [inches, setInches] = useState("");
  const [weight, setWeight] = useState("");
  const [currentTeam, setCurrentTeam] = useState("");
  const [position, setPosition] = useState("");
  const { setLoading } = useContext(ApiContext);

  //

  let profile = localStorage.getItem("profile");
  console.log(JSON.parse(profile));
  profile = JSON.parse(profile);

  //

  /*

    Load Profile Data component

  */

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profile._id}`
        );
        const data = await response.json();

        setFollowers(data.data.followers.length);
        setFollowing(data.data.following.length);
        setProfileImage(data.data.profileImage);
        setProfileData(data.data);
        setUsername(data.data.username);
        setTitle(data.data.title);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProfileData();
  }, [profile._id]);

  /*

    Load Athlete Data component

  */

  useEffect(() => {
    // Fetch athlete information
    const fetchAthleteInfo = async () => {
      let profile = localStorage.getItem("profile");
      console.log(JSON.parse(profile));
      profile = JSON.parse(profile);

      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profile._id}/athlete`
        );

        const data = await response.json();
        console.log("Fetch athlete Info");
        console.log(data);
        if (data.success) {
          console.log("Athlete Data");
          setAthleteData(data.data);

          // Set individual athlete info
          setSport(data.data.sport);
          setFeets(data.data.feets);
          setInches(data.data.inches);
          setWeight(data.data.weight);
          setCurrentTeam(data.data.currentTeam);
          setPosition(data.data.position);

          //

          //

          setPts(data.data.pts);
          setAst(data.data.ast);
          setRpg(data.data.rpg);
          setStl(data.data.stl);
          setBpg(data.data.bpg);
        }
      } catch (error) {
        console.error("Error fetching athlete info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAthleteInfo();
  }, [profile._id]);

  //

  const handleChatWithProfile = () => {
    navigate(`${API_ENDPOINT}/chat/id/${profileData._id}`);
  };

  //

  return (
    <>
      <Header />

      <Container
        className="create-account-container"
        style={{ paddingBottom: "150px" }}
      >
        {profileData && (
          <div>
            <div
              className="text-white text-center"
              style={{
                lineHeight: "14px",
                padding: "10px",
                display: "inline-block",
                background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                borderRadius: "15px",
                cursor: "pointer",
              }}
            >
              <Link
                to="/home"
                style={{
                  color: "#fff",
                  fontSize: "0.8em",
                  textDecoration: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ marginRight: "5px" }}
                />{" "}
                Back&nbsp;&nbsp;&nbsp;
              </Link>
            </div>
            <UserComponent
              userData={{
                image: profileImage,
                name: title,
                nickname: username,
              }}
              profileData={profileData}
            />
            <div className="profile-image-wrapper">
              <div
                className="text-white text-center"
                style={{
                  lineHeight: "14px",
                  padding: "10px",
                  display: "inline-block",
                  background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
              >
                <Link
                  to="/profile/athlete/edit"
                  style={{
                    color: "#fff",
                    fontSize: "0.8em",
                    textDecoration: "none",
                  }}
                >
                  Edit Profile
                </Link>
              </div>
            </div>
          </div>
        )}

        {profileData && (
          <Row>
            <Col xs={12} className="text-align">
              <FollowersFollowing followers={followers} following={following} />
            </Col>
          </Row>
        )}

        {profileData && <SocialMedia profileData={profileData} />}

        {profileData && <ProfileViews profileData={profileData} />}

        {athleteData && <ProfileStatistics profileData={athleteData} />}

        {athleteData && <AthleteChart athleteData={athleteData} />}

        {/* <div className="video-container">
          <Player
            playsInline
            poster="https://ksac.kiit.ac.in/wp-content/uploads/2021/02/10-Ways-To-Cope-Up-With-Online-Classes-scaled.jpg"
            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            width={240}
            height={136}
          />
        </div> */}

        {profileData?.highlights && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ScrollableHighlightsRow
              images={[
                {
                  src: profileData?.highlights,
                },
              ]}
            />
          </div>
        )}
        {/*
        <ScrollableInstagramPhotosRow images={[
            {title : 'No Instagram photo added', src: '' },
        ]} />

        <ScrollableNewsRow images={[
            {src: '', title: 'No news added', city : '', country : '', date : '07/15/2023'},
        ]} />

        */}

        <div style={{ height: "100px" }}></div>
      </Container>

      <Footer />
    </>
  );
}

export default MyProfileAthlete;
